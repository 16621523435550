/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.css');
require('../css/menu.css');
require('../css/fonts.css');
require('../css/responsive.css');

require('bootstrap/dist/css/bootstrap-grid.min.css');

require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('../css/slick-theme-echelle.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
// require('popper.js');
// require('bootstrap');
require('slick-carousel');

require('@fortawesome/fontawesome-free/css/all.min.css');
require('@fortawesome/fontawesome-free/js/all.min.js');

import ScrollReveal from 'scrollreveal';
ScrollReveal().reveal('.fade-in', {
    distance: '110%',
    origin: 'bottom',
    delay: 200,
    duration: 500,
    reset: true
});
ScrollReveal().reveal('.fade-right', {
    distance: '110%',
    origin: 'right',
    delay: 400,
    duration: 800,
    reset: true
});
ScrollReveal().reveal('.fade-left', {
    distance: '110%',
    origin: 'left',
    delay: 200,
    duration: 800,
    reset: true
});

$(document).ready(function() {
    $('.header-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true
    });

    $('.echelle-slider').slick({
        dots: true,
        arrows: true,
        infinite: true
    });

    $('.echelle-slider#northwood .slick-next').before($('.echelle-slider#northwood .slick-prev'));
    $('.echelle-slider#northwood .slick-prev, .echelle-slider#northwood .slick-next').wrapAll('<div class="arrow-group"></div>');
    $('.echelle-slider#ludwig .slick-next').before($('.echelle-slider#ludwig .slick-prev'));
    $('.echelle-slider#ludwig .slick-prev, .echelle-slider#ludwig .slick-next').wrapAll('<div class="arrow-group"></div>');

    $('.menu-main').mouseenter(function() {
        $('.menu-main-bg').css('background', '#454545');
    });

    $('.menu-main').mouseleave(function() {
        $('.menu-main-bg').css('background', 'transparent');
        $('.menu-main-bg').css('height', 55);
        $('.menu-main ul li:nth-last-child(1)').css('margin-top', 25);
        $('.menu-main li ul').css('display', 'none');
    });

    $('.menu-main li').click(function() {
        $('.menu-main-bg').css('height', $(this).find('ul').height() + 80);
        $('.menu-main ul li:nth-last-child(1)').css('margin-top', $(this).find('ul').height() + 20);
        $('.menu-main li ul li:nth-last-child(1)').css('margin-top', 0);
    });

    $('.menu-main ul li a').click(function() {
        $('.menu-main ul ul').hide();
        $('.menu-main ul li a').removeClass("rotate");
        $(this).addClass("rotate");
        $(this).parents('ul').show();
        $(this).next('.menu-item-has-children ul').toggle();
    });

    $('.second-menu-main ul li').mouseenter(function() {
        $('.second-menu').css('height', '150px');
        $(this).children('ul').show('100');
    });

    $('.second-menu-main ul li').mouseleave(function() {
        $('.second-menu').css('height', '69px');
        $(this).children('ul').hide();
    });

    $('.question-faq').click(function() {
        const color = $(this).parents('.category-faq').css("color");
        const width = $('.container').width();
        const parent = $(this).parent();
        let reponse = $(parent).children('.reponse-faq');
        const height = reponse.height();
        let next = $(parent).next();
        const arrow = $(this).children('.arrow');

        if (next.length == 0) {
            next = $(this).parents('.category-faq').next();
        }
        
        if (reponse.is(':visible')) {
            reponse.fadeOut('slow');
            next.css('margin-top', 0);
            $(this).css({ 'transition': '.5s all .3s'});
            $(this).css('color', color);
            $(this).css('background-color', 'white');
            $('.block-faq, .category-faq').css('transition', '.5s all .3s');
            arrow.css({ 'transform': 'rotate(0deg)'});
            arrow.css({ 'transition': '.5s all .3s'});
        } else {
            reponse.fadeIn();
            reponse.css('width', width);
            next.css('margin-top', height + 50);
            $(this).css({ 'transition': 'all .3s'});
            $(this).css('color', 'white');
            $(this).css('background-color', color);
            $('.block-faq, .category-faq').css('transition', '.5s all');
            arrow.css({ 'transform': 'rotate(180deg)'});
        }
    });
});

window.onscroll = () => {
    let currentScrollPos = window.pageYOffset;
    if (currentScrollPos > 50) {
        document.getElementById("second-nav-main").style.top = "0";
    } else {
        document.getElementById("second-nav-main").style.top = "-70px";
    }
}